<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout justify-end
                  class="mb-8">
            <v-btn color="secondary"
                   v-if="canAddType"
                   @click="openEditForm()"
                   data-test="addNewIncidentBtn">{{ $t('buttons.add') }}</v-btn>
        </v-layout>
        <v-data-table :headers="headers"
                      :loading="loader"
                      disable-sort
                      :items="incidentTypes"
                      disable-pagination
                      hide-default-footer
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                :key="i"
                                @sort="sort($event)"></SortableHeader>
            </template>
            <template v-slot:item.name="{ item }">
                <Type v-if="item.id"
                      :type="getType(item)"></Type>
            </template>
            <template v-slot:item.anonymous="{ item }">
                <HintingIcon color="secondary"
                             v-if="item.anonymous"
                             :icon="ICONS.CHECK_CIRCLE"
                             :tooltip="$t('incidents.table.headers.anonymous')"></HintingIcon>
            </template>

            <template v-slot:item.actions="{ item }">
                <HintingIcon class-name="mr-2"
                             v-if="canEditType"
                             :icon="ICONS.EDIT"
                             @click="openEditForm(item.id)"
                             :data-test="item"
                             :tooltip="$t('actions.edit')"></HintingIcon>
                <ButtonWithConfirmation :item-id="item.id"
                                        v-if="canDeleteType"
                                        :callback="deleteType">

                </ButtonWithConfirmation>
                <ManageAssignToLocation type="kinds" v-if="canEditType"
                                        :id="item.id"></ManageAssignToLocation>
            </template>
        </v-data-table>

        <Pagination :pagination="pagination"
                    data-test="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import HintingIcon from '@/components/widgets/HintingIcon';
import Pagination from '@/components/widgets/tables/Pagination';
import Type from '@/components/widgets/Type';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import ManageAssignToLocation from '@/components/templatesConfigurations/assignToLocations/ManageAssignToLocation';

export default {
    name: 'IncidentsTypesTable',
    components: { ManageAssignToLocation, SortableHeader, HintingIcon, Pagination, Type, ButtonWithConfirmation },
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        changedPage: {
            type: [String, Number],
            required: false
        }
    },
    data: () => ({
        ICONS,
        pagination: {
            page: 1,
            amount: 20,
            amountAll: null
        },
        sortable: {
            name: 'asc'
        }
    }),
    computed: {
        ...mapState('incidents', [
            'incidentTypes',
            'totalIncidentTypesAmount'
        ]),
        ...mapState('manageIncident', [
            'activeIncidentType'
        ]),
        ...mapFields('manageIncident', [
            'incidentTypeFormVisibility',
            'editedIncidentTypeId'
        ]),
        headers () {
            return [
                {
                    text: this.$t('incidents.table.headers.seq'),
                    value: 'seq',
                    width: '80px',
                    sortable: false
                },
                {
                    text: this.$t('labels.incident_type'),
                    value: 'name',
                    sortable: true
                },
                {
                    text: this.$t('incidents.table.headers.anonymous'),
                    value: 'anonymous',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('incidents.table.headers.actions'),
                    value: 'actions',
                    icon: false,
                    width: '200px',
                    sortable: false
                }
            ];
        },
        canEditType () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.INCIDENT_TYPES);
        },
        canDeleteType () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.INCIDENT_TYPES);
        },
        canAddType () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.INCIDENT_TYPES);
        }
    },
    watch: {
        totalIncidentTypesAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        }
    },
    methods: {
        getKey (item) {
            return Object.values(item).flat().toString();
        },
        getType (item) {
            return {
                id: item.id,
                name: item.name,
                color: item.color
            };
        },
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'desc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: event,
                value: this.sortable[event]
            });
        },
        emitFetch () {
            this.$emit('fetch');
        },
        deleteType (id) {
            return this.$store.dispatch('manageIncident/deleteIncidentType', id)
                .then(() => {
                    this.emitFetch();
                });
        },
        openEditForm (id) {
            if (id) {
                this.$store.dispatch('manageIncident/getActiveIncidentType', id)
                    .then(() => {
                        this.$store.commit('manageIncident/SET_INCIDENT_TYPE_DATA', this.activeIncidentType);
                        this.incidentTypeFormVisibility = true;
                        this.editedIncidentTypeId = id;
                    });
            } else {
                this.incidentTypeFormVisibility = true;
            }
        }

    },
    mounted () {
        this.$set(this.sortable, `${this.name}`, null);
    }
};
</script>

<style scoped>

</style>
