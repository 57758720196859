<template>
    <Modal :modal-title="title"
           :dialog="incidentTypeFormVisibility"
           max-width="1000px"
           :persistent="true"
           :closeIcon="false"
           dense
           data-test="modal"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ManageIncidentTypeForm ref="form"
                                :is-editable="isEditable"></ManageIncidentTypeForm>
            <ConfirmationDialog :dialog="showConfirmationDialog"
                                data-test="ConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>
            <span data-test="testKeydownSpan"></span>

            <ValidationError v-if="showError"></ValidationError>
        </template>
        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import ManageIncidentTypeForm from '@/components/incidentsTypes/manageIncidentType/manageIncidentTypeForm';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'ManageIncidentType',
    components: { Modal, ManageIncidentTypeForm, ConfirmationDialog, ValidationError },
    data: () => ({
        showConfirmationDialog: false,
        loader: false,
        showError: false
    }),
    computed: {
        ...mapFields('manageIncident', [
            'incidentTypeFormVisibility',
            'editedIncidentTypeId'
        ]),
        ...mapFields('templates', [
            'rawTemplates',
            'totalTemplatesAmount'
        ]),
        ...mapState('manageIncident', [
            'showDetails'
        ]),
        title () {
            return this.editedIncidentTypeId ? this.$t('buttons.edit') : this.$t('incidents.add_new_incident_type');
        },
        isFieldsDirty () {
            return this.$refs.form.isFormDirty;
        },
        isEditable () {
            return this.editedIncidentTypeId !== null;
        }
    },
    methods: {
        close () {
            this.incidentTypeFormVisibility = false;
            this.$refs.form.resetValidation();
            // prevent showing bad data 0.1s
            setTimeout(() => {
                this.totalTemplatesAmount = null;
                this.rawTemplates = [];
                this.showError = false;
                this.editedIncidentTypeId = null;
                this.$store.dispatch('manageIncident/clearIncidentTypeData');
            }, 100);
        },
        visibility (event) {
            this.incidentTypeFormVisibility = event;
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        updateIncidentType () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('manageIncident/updateIncidentType')
                            .then(() => {
                                this.$emit('fetch');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        createIncidentType () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('manageIncident/createIncidentType')
                            .then(() => {
                                this.$emit('fetch');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        save () {
            if (this.isEditable) {
                this.updateIncidentType();
            } else {
                this.createIncidentType();
            }
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
