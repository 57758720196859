import { render, staticRenderFns } from "./IncidentsTypes.vue?vue&type=template&id=036f60b4&scoped=true&"
import script from "./IncidentsTypes.vue?vue&type=script&lang=js&"
export * from "./IncidentsTypes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "036f60b4",
  null
  
)

export default component.exports