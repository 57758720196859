import debounce from 'lodash.debounce';
import { mapState } from 'vuex';
import { FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        searchCompanyTypes: null,
        isCompanyTypesLoading: false,
        companyTypesPaginationPage: 1,
        debouncedGetCompanyTypes: null
    }),
    computed: {
        ...mapState('companyTypes', [
            'rawCompanyTypes',
            'totalCompanyTypesAmount'
        ])
    },
    watch: {
        searchCompanyTypes: {
            handler (val) {
                if (!val || this.isCompanyTypesLoading) {
                    return;
                }

                this.debouncedGetCompanyTypes(val);
            }
        }
    },
    methods: {
        getCompanyTypes (additionalParams = null) {
            this.isCompanyTypesLoading = true;

            let params = {};

            if (typeof additionalParams === 'string') {
                params['filter[name][match]'] = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }

            return this.$store.dispatch('companyTypes/getCompanyTypes', params)
                .then(() => {
                    this.companyTypesPaginationPage = 1;
                })
                .finally(() => {
                    this.isCompanyTypesLoading = false;
                });
        },
        getCompanyTypesLazyLoading (additionalParams = null) {
            if (this.totalCompanyTypesAmount > this.rawCompanyTypes.length) {
                this.isCompanyTypesLoading = true;

                this.companyTypesPaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object') {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.companyTypesPaginationPage;

                const _name = this.searchCompanyTypes || this.filtersSearching?.[FILTERS_SEARCHING.COMPANY_TYPES];

                if (_name) {
                    params['filter[name][match]'] = _name;
                }

                return this.$store.dispatch('CompanyTypes/getCompanyTypesLazyLoading', params)
                    .finally(() => {
                        this.isCompanyTypesLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetCompanyTypes = debounce(this.getCompanyTypes, 1000);
    }
};
