var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"mb-8",attrs:{"justify-end":""}},[(_vm.canAddType)?_c('v-btn',{attrs:{"color":"secondary","data-test":"addNewIncidentBtn"},on:{"click":function($event){return _vm.openEditForm()}}},[_vm._v(_vm._s(_vm.$t('buttons.add')))]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loader,"disable-sort":"","items":_vm.incidentTypes,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([_vm._l((_vm.headers),function(headerItem,i){return {key:("header." + (headerItem.value)),fn:function(ref){
var header = ref.header;
return [_c('SortableHeader',{key:i,attrs:{"header-item":header},on:{"sort":function($event){return _vm.sort($event)}}})]}}}),{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('Type',{attrs:{"type":_vm.getType(item)}}):_vm._e()]}},{key:"item.anonymous",fn:function(ref){
var item = ref.item;
return [(item.anonymous)?_c('HintingIcon',{attrs:{"color":"secondary","icon":_vm.ICONS.CHECK_CIRCLE,"tooltip":_vm.$t('incidents.table.headers.anonymous')}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEditType)?_c('HintingIcon',{attrs:{"class-name":"mr-2","icon":_vm.ICONS.EDIT,"data-test":item,"tooltip":_vm.$t('actions.edit')},on:{"click":function($event){return _vm.openEditForm(item.id)}}}):_vm._e(),(_vm.canDeleteType)?_c('ButtonWithConfirmation',{attrs:{"item-id":item.id,"callback":_vm.deleteType}}):_vm._e(),(_vm.canEditType)?_c('ManageAssignToLocation',{attrs:{"type":"kinds","id":item.id}}):_vm._e()]}}],null,true)}),_c('Pagination',{attrs:{"pagination":_vm.pagination,"data-test":"pagination"},on:{"change-page":function($event){return _vm.changePage($event)},"change-amount":function($event){return _vm.changeAmount($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }